<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="EditLine()">
      <div class="mb-3">

        <div class="form-group">
          <label>debut </label>
          <input type="text" :class="errors.debut?'form-control is-invalid':'form-control'"
                 v-model="form.debut">

          <div class="invalid-feedback" v-if="errors.debut">
            <template v-for=" error in errors.debut"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>fin </label>
          <input type="text" :class="errors.fin?'form-control is-invalid':'form-control'"
                 v-model="form.fin">

          <div class="invalid-feedback" v-if="errors.fin">
            <template v-for=" error in errors.fin"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>users </label>
          <input type="text" :class="errors.users?'form-control is-invalid':'form-control'"
                 v-model="form.users">

          <div class="invalid-feedback" v-if="errors.users">
            <template v-for=" error in errors.users"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>programmations </label>

          <CustomSelect
              :key="form.programmation"
              :url="`${axios.defaults.baseURL}/api/programmations-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.programmation"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.programmation_id=data.id;form.programmation=data}"
          />
          <div class="invalid-feedback" v-if="errors.programmation_id">
            <template v-for=" error in errors.programmation_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <div class="d-flex justify-content-between">
        <button type="submit" class="btn btn-primary">
          <i class="fas fa-floppy-disk"></i> Mettre à jour
        </button>
        <button type="button" class="btn btn-danger" @click.prevent="DeleteLine()">
          <i class="fas fa-close"></i> Supprimer
        </button>
      </div>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'EditProgrammationsdetails',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: ['data', 'gridApi', 'modalFormId',
    'programmationsData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        debut: "",

        fin: "",

        users: "",

        programmation_id: "",
      }
    }
  },

  mounted() {
    this.form = this.data
  },
  methods: {

    EditLine() {
      this.isLoading = true
      this.axios.post('/api/programmationsdetails/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    DeleteLine() {
      this.isLoading = true
      this.axios.post('/api/programmationsdetails/' + this.form.id + '/delete').then(response => {
        this.isLoading = false

        this.gridApi.applyServerSideTransaction({
          remove: [
            this.form
          ]
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de la suppression')
      })
    },
  }
}
</script>
